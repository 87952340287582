import * as React from 'react';
import * as styles from '../../styles/global.module.css';

import { Box, Heading, Text, VStack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';

const PrivacyPolicy: FunctionComponent = () => {
	return (
		<>
			<Layout>
				<Box
					maxW='1280px'
					margin='0px auto'
					pt='120px'
					pb='80px'
					px='20px'>
					<VStack pb='48px'>
						<Box
							className={styles.divider}
							w='1.5rem'
							height='0.13rem'
							color='red.500'
						/>
						<Box>
							<Heading
								as='h1'
								fontWeight='400'
								className={styles.FadeHeading}>
								Privacy Policy
							</Heading>
						</Box>
					</VStack>
					<VStack
						gap='1.25rem'
						alignItems='flex-start'>
						<b>Effective Date: August 16, 2011</b>
						<Box>
							<Text pb='1.25rem'>
								At, Doctor Medica ("DoctorMedica.co"), we are committed to
								preserving the privacy interests of our customers. We have
								established this Privacy Policy to set out the principles that
								we follow in handling customer personal information we collect
								on www.DoctorMedica.co.
							</Text>
							<Text>
								In order to create and maintain a customer account and to place
								orders for products or services on our site or through our
								toll-free telephone and facsimile numbers, it is necessary for
								DoctorMedica.co to collect detailed personal information from
								customers and to obtain consent to disclose this information to
								other service providers and health professionals to facilitate
								customer requests for products and/or services. In collecting
								and administering this information, we follow the principles set
								out below.
							</Text>
						</Box>
						<Box>
							<b>Principle 1 - Accountability</b>
						</Box>
						<Box>
							DoctorMedica.co is responsible for maintaining and protecting
							personal information under its control. We monitor our policies
							and personal information safeguards on an ongoing basis.
						</Box>
						<Box>
							<b>Principle 2 - Identifying Purposes</b>
						</Box>
						<Box>
							<Text>
								DoctorMedica.co collects customer personal information such as
								you name, address, email address, phone number, and fax number
								to register for an account. In order to provide requested
								products and/or services and to facilitate the fulfillment of
								customer orders and payment processing we will collect
								additional information such as billing information. In
								connection with the use of our web site, we may also collect
								certain information through the use of cookies, which improves
								your ability to use our web site. Should you wish to disable the
								use of cookies, simply adjust your web browser settings
								accordingly. To ensure the highest level of customer service,
								this call may be monitored and recorded.
							</Text>
							<p>&nbsp;</p>
							<p>
								We do not link the information we store in cookies to any
								personally identifiable information you submit while on our
								site.
							</p>
							<p>&nbsp;</p>
							<p>
								We use session ID cookies. We use session cookies to make it
								easier for you to navigate our site. A session ID cookie expires
								when you close you browser. A persistent cookie remains on your
								hard drive for an extended period of time. You can remove
								persistent cookies by following directions provided in your
								Internet browser’s “help” file.
							</p>
							<p>&nbsp;</p>
							<Text>
								As is true of most Web sites, we gather certain information
								automatically and store it in log files. This information
								includes internet protocol (IP) addresses, browser type,
								internet service provider (ISP), referring/exit pages, operating
								system, date/time stamp, and clickstream data.
							</Text>
							<p>&nbsp;</p>
							<Text>
								We use this information, which does not identify individual
								users, to analyze trends, to administer the site, to track
								users’ movements around the site and to gather demographic
								information about our user base as a whole.
							</Text>
						</Box>
						<Box>
							<b>Principle 3 - Consent</b>
						</Box>
						<Box>
							<p>
								DoctorMedica.co collects, uses and discloses customer personal
								information only with the knowledge and consent of customers.
							</p>
							<p>&nbsp;</p>
							<p>
								Please note that DoctorMedica.co may collect, use or disclose
								personal information without knowledge or consent in exceptional
								circumstances or to address legal matters, for example, if there
								is an emergency where the life, health or security of the
								customer is threatened or where required to do so by applicable
								law.
							</p>
							<p>&nbsp;</p>
							<p>
								If DoctorMedica.co is involved in a merger, acquisition, or sale
								of all or a portion of its assets, you will be notified via
								email and/or a prominent notice on our Web site of any change in
								ownership or uses of your personal information, as well as any
								choices you may have regarding your personal information.
							</p>
							<p>&nbsp;</p>
							<p>
								We will use your name and email address to send you newsletters
								and offers which we believe are of value to you. If you no
								longer wish to receive these communications you may follow the
								unsubscribe instruction contained in the email communications
								you receive.
							</p>
							<p>&nbsp;</p>
							<p>
								If you choose to use our referral service to tell a friend about
								our site, we will ask you for your friend’s name and email
								address. We will automatically send your friend a one-time email
								inviting him or her to visit the site. DoctorMedica.co does not
								store this information.
							</p>
							<p>&nbsp;</p>
							<p>
								This Web site contains links to other sites that are not owned
								or controlled by DoctorMedica.co. Please be aware that we,
								DoctorMedica.co, are not responsible for the privacy practices
								of such other sites.
							</p>
							<p>&nbsp;</p>
							<p>
								We encourage you to be aware when you leave our site and to read
								the privacy statements of each and every Web site that collects
								personally identifiable information.
							</p>
							<p>&nbsp;</p>
							<p>
								This privacy statement applies only to information collected by
								this Web site.
							</p>
						</Box>
						<Box>
							<b>Principle 4 - Limiting Collection</b>
						</Box>
						<Box>
							DoctorMedica.co shall collect only that personal information that
							is reasonably necessary to process customer orders, including as
							required by third party service providers such as a shipping
							company to fulfill orders, and a credit card processing company to
							bill you for goods and services, and health professionals to
							assist us in filling your orders.
						</Box>
						<Box>
							<b>Principle 5 - Limiting Use, Disclosure and Retention</b>
						</Box>
						<Box>
							DoctorMedica.co shall not use or disclose personal information for
							purposes other than those for which it was collected, except with
							the consent of the customer, or as otherwise outlined in this
							privacy policy or as required by law. If you wish to cancel your
							account or request that we no longer use your information to
							provide you services contact us at{' '}
							<a href='mailto:info@doctormedica.co'>info@doctormedica.co</a>.
							Customer personal information will be retained as is necessary to
							fulfill the purposes for which it was collected, to best serve
							customer health needs and as necessary to comply with our legal
							obligations, resolve disputes, and enforce our agreements.
						</Box>
						<Box>
							<b>Principle 6 - Accuracy</b>
						</Box>
						<Box>
							Personal Information that is maintained by DoctorMedica.co shall
							be as accurate, complete, and up-to-date as is necessary for the
							purposes for which it is used. DoctorMedica.co shall confirm all
							personal information collected from customers each time goods
							and/or services are requested.
						</Box>
						<Box>
							<b>Principle 7 - Security Safeguards</b>
						</Box>
						<Box>
							Given the sensitive nature of the personal information collected,
							DoctorMedica.co shall employ generally accepted security
							safeguards appropriate to the sensitivity of the information, and
							shall take steps to protect personal information against loss or
							theft, unauthorized access, disclosure, copying, use or
							modification. When you enter sensitive information (such as a
							credit card number) on our order forms, we encrypt the
							transmission of that information using secure socket layer
							technology (SSL).
						</Box>
						<Box>
							<b>Principle 8 - Openness</b>
						</Box>
						<Box>
							DoctorMedica.co personnel are available to answer questions and
							provide information concerning its policies and practices that
							apply to the management of personal information.
						</Box>
						<Box>
							<b>Principle 9 - Customer Access</b>
						</Box>
						<Box>
							Upon request, DoctorMedica.co will inform a customer of the
							existence, use and disclosure of his/her personal information, and
							grant access to such information as appropriate. Customers are
							able to verify the accuracy and completeness of such information
							either by logging into their account and making the changes in the
							account management center or by contacting us via email at{' '}
							<a href='mailto:info@doctornedica.com'>info@doctormedica.co</a>.
							If for some reason, we are unable to grant access to certain
							information, the reasons for denying access will be provided to
							the customer upon request.
						</Box>
						<Box>
							<b>Principle 10 – Changes to this privacy policy</b>
						</Box>
						<Box>
							We may update this privacy statement to reflect changes to our
							information practices. If we make any material changes we will
							notify you by email (sent to the e-mail address specified in your
							account) or by means of a notice on this Site prior to the change
							becoming effective. We encourage you to periodically review this
							page for the latest information on our privacy practices.
						</Box>
						<Box>
							<b>Principle 11 – Customer Service Inquiries and Complaints</b>
						</Box>
						<Box>
							<a
								href='/contact-us'
								target='_blank'
								rel='noopener'>
								Contact page.
							</a>
						</Box>
					</VStack>
				</Box>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Doctor Medica - Privacy Policy'
		description='Our Privacy Policy is available for you to review. We recommend that you review our privacy policy.'
	/>
);
export default PrivacyPolicy;
